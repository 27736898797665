import { useState, useEffect } from 'react';
import axios from 'axios';
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";

const TaskReportDownload = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [month, setMonth] = useState('');
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });

  useEffect(() => {
    axios.get('https://sf.doaguru.com/api/users')
      .then(response => setUsers(response.data))
      .catch(error => console.error("Error fetching users:", error));
  }, []);

  useEffect(() => {
    if (selectedUserId) {
      let url = `https://sf.doaguru.com/api/getUserTasks/${selectedUserId}`;
      if (month) url += `?month=${month}`;
      if (dateRange.startDate && dateRange.endDate) {
        url += `${month ? '&' : '?'}startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
      }

      axios.get(url)
        .then(response => setTasks(response.data))
        .catch(error => console.error("Error fetching tasks:", error));
    }
  }, [selectedUserId, month, dateRange]);

  const handleUserChange = (e) => setSelectedUserId(e.target.value);
  const handleMonthChange = (e) => setMonth(e.target.value);
  const handleDateChange = (e) => {
    setDateRange({ ...dateRange, [e.target.name]: e.target.value });
  };

  const handleDownload = () => {
    let downloadUrl = `https://sf.doaguru.com/api/downloadUserTasks/${selectedUserId}`;
    if (month) downloadUrl += `?month=${month}`;
    if (dateRange.startDate && dateRange.endDate) {
      downloadUrl += `${month ? '&' : '?'}startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
    }
    window.location.href = downloadUrl;
  };

  const handleResetFilters = () => {
    setMonth('');
    setDateRange({ startDate: '', endDate: '' });
    setTasks([]);
  };

  return (
<div className="container mx-auto px-4">
  <h1 className="text-center font-bold text-2xl md:text-3xl lg:text-4xl my-3">Admin Report Dashboard</h1>
  
  <div className="w-full flex flex-col sm:flex-wrap md:flex-row md:items-center md:justify-between my-3 space-y-2 md:space-y-0 gap-2 md:space-x-4">
    
    <div className="flex flex-col md:flex-row items-start md:items-center gap-1 px-5">
      <label className="text-xl md:text-lg lg:text-xl">Select User:</label>
      <select onChange={handleUserChange} className="border-2 rounded-lg px-2 py-1 border-cyan-600 w-full md:w-auto">
        <option value="">Select a user</option>
        {users.map(user => (
          <option key={user.id} value={user.id}>{user.full_name}</option>
        ))}
      </select>
    </div>

    <div className="flex flex-col md:flex-row items-start md:items-center gap-1 px-5">
      <label className="text-xl md:text-lg lg:text-xl">Select Month:</label>
      <select onChange={handleMonthChange} value={month} className="border-2 rounded-lg px-2 py-1 border-cyan-600 w-full md:w-auto">
        <option value="">All</option>
        <option value="01">January</option>
        <option value="02">February</option>
        <option value="03">March</option>
        <option value="04">April</option>
        <option value="05">May</option>
        <option value="06">June</option>
        <option value="07">July</option>
        <option value="08">August</option>
        <option value="09">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </select>
    </div>

    <div className="flex flex-col md:flex-row items-start md:items-center gap-1 px-5">
      <label className="text-xl md:text-lg lg:text-xl">From:</label>
      <input type="date" name="startDate" onChange={handleDateChange} value={dateRange.startDate} className="border-2 rounded-lg px-2 py-1 border-cyan-600 w-full md:w-auto" />
      <label className="text-xl md:text-lg lg:text-xl">To:</label>
      <input type="date" name="endDate" onChange={handleDateChange} value={dateRange.endDate} className="border-2 rounded-lg px-2 py-1 border-cyan-600 w-full md:w-auto" />
    </div>

    <div className="flex justify-end md:justify-start px-5">
      <button onClick={handleResetFilters} className="border-2 rounded-lg px-3 py-1 border-red-600 text-red-600 hover:bg-red-700 hover:text-white font-bold w-full md:w-auto">
        Reset Filters
      </button>
    </div>
  </div>

  <div className="flex flex-col items-center">
    <h2 className="text-center font-bold text-2xl md:text-3xl lg:text-4xl my-3">Tasks</h2>
    
    <div className="relative mx-4 overflow-x-auto shadow-md sm:rounded-lg w-full">
      {tasks.length > 0 ? (
        <table className="w-full text-sm text-left text-white dark:text-gray  ">
          <thead className="text-xs text-gray-700 dark:text-gray-200 uppercase bg-gray-300 dark:bg-gray-500 ">
            <tr>
              <th className="px-4 py-2">Project or Client Name</th>
              <th className="px-4 py-2">Category</th>
              <th className="px-4 py-2">SubCategory</th>
              <th className="px-4 py-2">Task Description</th>
              <th className="px-4 py-2">Consuming Time (Min)</th>
              <th className="px-4 py-2">Task Date</th>
            </tr>
          </thead>
          <tbody>
            {tasks.slice().reverse().map(task => (
              <tr key={task.id} className="bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-50 border-b dark:border-gray-700">
                <td className="px-4 py-2">{task.ProjectOrClientName}</td>
                <td className="px-4 py-2">{task.Category}</td>
                <td className="px-4 py-2">{task.SubCategory}</td>
                <td className="px-4 py-2">{task.TaskDescription}</td>
                <td className="px-4 py-2">{task.ConsumingTimeInMin}</td>
                <td className="px-4 py-2">{task.task_date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-red-600 font-bold m-1 p-2">No tasks found for the selected user.</p>
      )}
    </div>
  </div>

  <div className="flex justify-center mt-5">
    <button onClick={handleDownload} disabled={!selectedUserId} className="flex items-center gap-1 border-2 rounded-md border-green-500 p-2 hover:bg-green-700 text-gray-600 font-bold hover:text-white">
      <PiMicrosoftExcelLogoFill />
      Download Tasks as Excel
    </button>
  </div>
</div>

  );
};

export default TaskReportDownload;
