import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaIdCard, FaSignOutAlt, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';


function EmployeeCard() {

  const [employeeData, setEmployeeData] = useState(null);
  const [error, setError] = useState(null);

  const employeeId = JSON.parse(localStorage.getItem('user'));
  

  useEffect(() => {
    // Fetch employee data on component mount
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get(`https://sf.doaguru.com/api/getEmployee/${employeeId.id}`);
        setEmployeeData(response.data);
        // console.log(response.data);
        
      } catch (error) {
        setError('Failed to load employee data');
        console.error('Error:', error);
      }
    };
    fetchEmployeeData();
  }, [employeeId]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!employeeData) {
    return <p>Loading employee data...</p>;
  }


  const handlePrint = () => {
    window.print();
  };

  return (
   
    <div className="flex min-h-screen bg-gray-100 overflow-scroll">
          {/* Sidebar */}
          <div className="w-1/6 bg-gray-800 text-white flex flex-col items-center py-8">
      <ul className="space-y-4 mt-5">
        {/* Profile */}
        <li className="text-lg cursor-pointer hover:text-blue-300 flex items-center">
        <Link to="/task/EmployeeDashboard"><FaUser className="mr-2 text-xl" /></Link>
          <Link to="" className="hidden md:inline">Profile</Link>
        </li>
        
        {/* Employee Card */}
        <li className="text-lg cursor-pointer hover:text-blue-300 flex items-center">
          <Link to="/task/EmployeeProfile Card"><FaIdCard className="mr-2 text-xl"  /></Link>
          <Link to="/task/EmployeeProfile Card" className="hidden md:inline">Employee Card</Link>
        </li>

        {/* Logout */}
        <li className="text-lg cursor-pointer hover:text-blue-300 flex items-center">
          <FaSignOutAlt className="mr-2 text-xl" />
          <span className="hidden md:inline">Report</span>
        </li>
      </ul>
    </div>

    <div className='flex-1 flex flex-column justify-center items-center '>
      {/* Card Container */}
      <div id="card" className="w-[40rem] h-[30rem] flex gap-3 border-2 border-gray-300 shadow-lg bg-white print:border-none">
        
        {/* Front Side */}
        <div id="front-side" className="w-[20rem] p-4 flex flex-column border-2 border-gray-900 rounded-lg postion-relative ">
          <div>

          <div className='w-full h-28 bg-red-600'></div>
          <div className='flex m-auto w-24 h-24 bg-white rounded-full position-relative top-[-28%] '>
            <img src="https://doaguru.com/static/media/doagurulogo-removebg.b0126812bbe704a27f8f.webp" alt="Logo" className='w-[5rem] h-[3rem] m-auto' />
          </div>
            <div className='w-[1rem] h-[5rem] bg-red-600 position-relative top-[-8%] '></div>
            <div className='w-[1rem] h-[5rem] bg-red-600 position-relative top-[-30%] right-[-93%] md:right-[-93%]'></div>

          </div>
          <div className="flex flex-col items-center position-relative top-[-68%]">
            <img src={employeeData.profileIMG} alt="Profile IMG" className="w-24 h-24 rounded-full border-5 border-green-500 mb-4 p-1" />
            <h2 className="text-xl font-bold">{employeeData.full_name}</h2>
            <p className="text-gray-700 text-sm">{employeeData.designation}</p>
          </div>
          {/* <div className='w-[1rem] h-[5rem] bg-red-600  '></div>
          <div className='w-[1rem] h-[5rem] bg-red-600 '></div> */}

          
          <div className="mt-4 text-sm flex flex-column gap-2 position-relative top-[-68%]">
            <p><strong>ID Number:</strong> DG0000{employeeData.id}</p>
            <p><strong>Mob.:</strong> {employeeData.mobile_number}</p>
            <p><strong>Blood Group:</strong> {employeeData.bloodGroup}</p>
            <p><strong>DOB:</strong> {employeeData.DOB}</p>
            <p><strong>Date of Joining:</strong> {employeeData.joiningDate}</p>
          <div className='w-full h-[0.5rem]  bg-green-600 position-relative bottom-[-5%] '></div>
          </div>
        </div>
        

        {/* Back Side */}
        <div id="back-side" className="w-[20rem] p-4 bg-gray-100  flex flex-col items-center justify-center border-2 border-gray-900 rounded-lg">
          <div className="flex flex-col items-center">
            {/* Replace with company logo */}
            <img src="https://doaguru.com/static/media/doagurulogo-removebg.b0126812bbe704a27f8f.webp" alt="Company Logo" className="w-32 mb-4" />
            <p className="text-center text-sm font-semibold">DOAGuru InfoSystems</p>
            <p className="text-xs text-center mt-2">Transforming Tech Logically</p>
          </div>
          <div className='' >
            
          <div className="mt-4 text-xs">
            <h4 className="font-bold">Terms & Conditions:</h4>
            <p>This card is the property of DOAGuru InfoSystems. If found, please return it to the office. Misuse is prohibited.</p>
          </div>
          <div className="mt-4 text-xs">
            <h4 className="font-bold">Address:</h4>
            <p>1815 Wright Town,
Jabalpur, Madhya pradesh INDIA
482002 </p>
          </div>
          <div className="mt-4 text-xs flex flex-column items-start ">
            <h4 className="font-bold">Emergency Contact No:</h4>
            <p> +91-8827311424 </p>
          </div>
          </div>
        </div>

      </div>

      {/* Print Button */}
      <button onClick={handlePrint} className="mt-6 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
        Print Card
      </button>
    </div>
    </div>
    
  );
}

export default EmployeeCard;




