import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import PaginationControls from "../../components/Pagination";

function EmployeePage() {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

    // Pagination state
    const [currentPagetask, setCurrentPagetask] = useState(1);
    // Row per page state
    const [rowsPerPagetask, setRowsPerPagetask] = useState(5);
  
    //Pagination function 
    const getTotalPages = (data, rowsPerPage) => Math.ceil(data.length / rowsPerPage);
  
    const getCurrentRows = (data, currentPage, rowsPerPage) => {
      const indexOfLastRow = currentPage * rowsPerPage;
      const indexOfFirstRow = indexOfLastRow - rowsPerPage;
      return data.slice(indexOfFirstRow, indexOfLastRow);
    };
  
    const handleNextPage = (setter, currentPage, totalPages) => {
      if (currentPage < totalPages) {
        setter(currentPage + 1);
      }
    };
  
    const handlePreviousPage = (setter, currentPage) => {
      if (currentPage > 1) {
        setter(currentPage - 1);
      }
    };
    // handle row per page in table 
    const handleRowsPerPage = (event, setter) => {
      setter(parseInt(event.target.value));
    };

  useEffect(() => {
    axios.get('https://sf.doaguru.com/api/users')
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const openEditModal = (user) => {
    setSelectedEmployee(user);
    setIsModalOpen(true);
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
    setSelectedEmployee(null);
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    const updatedData = {
      id: selectedEmployee.id,
      full_name: selectedEmployee.full_name,
      designation: selectedEmployee.designation,
      email_id: selectedEmployee.email_id,
      mobile_number: selectedEmployee.mobile_number,
    };
  
    axios.put(`https://sf.doaguru.com/api/Update-Employee-Details`, updatedData)
      .then(() => {
        setUsers(users.map(user => user.id === selectedEmployee.id ? updatedData : user));
        closeEditModal();
        alert("Employee details updated successfully.");
      })
      .catch(error => {
        console.error("Error updating employee details:", error);
        alert("Failed to update employee details.");
      });
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this employee?");
    if (confirmDelete) {
      axios.delete(`https://sf.doaguru.com/api/delete-employee/${id}`)
        .then(() => {
          setUsers(users.filter((user) => user.id !== id));
          alert("Employee deleted successfully.");
        })
        .catch((error) => {
          console.error("Error deleting employee:", error);
          alert("Failed to delete the employee.");
        });
    }
  };

  return (
    <>
      <div className="flex m-auto justify-center">
        <div className="EmployeePage container sm:m-5 sm:p-5 flex gap-2 flex-col justify-center mt-16">
          <div className='flex justify-start '>
            <span className="m-1 p-1 hover:bg-cyan-100 border border-cyan-600 rounded-lg">
              <Link to="/task/registerUser">Register Employee</Link>
            </span>
          </div>
          <div className="flex">
            <h1 className="m-auto font-bold under">Show User Details</h1>
          </div>

          <div className="userTable">
            <div className="relative mx-4 overflow-x-auto shadow-md sm:rounded-lg">
            <div className=" Select-table-row mb-0.5">
          <span className="text-xs">Select Page </span>
          <select name="rowsPerPage" id="rowsPerPage" className="text-xs rounded border-1 " onChange={(e) => handleRowsPerPage(e, setRowsPerPagetask)}
            value={rowsPerPagetask}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
          </select>
        </div>
              <table className="w-full text-sm text-left rtl:text-right text-gray-700 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-3 py-2">S.no.</th>
                    <th scope="col" className="px-3 py-2">Employee ID</th>
                    <th scope="col" className="px-3 py-2">Employee Name</th>
                    <th scope="col" className="px-3 py-2">Designation</th>
                    <th scope="col" className="px-3 py-2">Joining Date</th>
                    <th scope="col" className="px-3 py-2">Email ID</th>
                    <th scope="col" className="px-3 py-2">Mobile No.</th>
                    <th scope="col" className="px-3 py-2 center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getCurrentRows(users, currentPagetask, rowsPerPagetask).map((user, index) => (
                    <tr key={index} className="odd:bg-white even:bg-gray-50 dark:odd:bg-gray-900 dark:even:bg-gray-800 border-b dark:border-gray-700">
                      <td className="px-3 py-2">{index + 1}</td>
                      <td className="px-3 py-2">DOAG{user.id}</td>
                      <td className="px-3 py-2">{user.full_name}</td>
                      <td className="px-3 py-2">{user.designation}</td>
                      <td className="px-3 py-2">{user.joining_date}</td>
                      <td className="px-3 py-2">{user.email_id}</td>
                      <td className="px-3 py-2">{user.mobile_number}</td>
                      <td className="px-3 py-2 flex-wrap">
                        <Link to="#" onClick={() => openEditModal(user)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline px-1">Edit</Link>
                        <Link to="#" onClick={() => handleDelete(user.id)} className="font-medium text-red-600 dark:text-red-500 hover:underline px-1">Remove</Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {getTotalPages(users, rowsPerPagetask) > 1 && (
          <PaginationControls
            currentPage={currentPagetask}
            totalPages={getTotalPages(users, rowsPerPagetask)}
            onNextPage={() => handleNextPage(setCurrentPagetask, currentPagetask, getTotalPages(users, rowsPerPagetask))}
            onPreviousPage={() => handlePreviousPage(setCurrentPagetask, currentPagetask)}
          />
        )}
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <Wrapper>
          <div className="modal">
            <div className="modal-content">
              <h2 className="font-bold">Edit Employee</h2>
              <form onSubmit={handleUpdate}>
                <label htmlFor="full_name">Name:</label>
                <input
                  id="full_name"
                  name="full_name"
                  type="text"
                  value={selectedEmployee.full_name}
                  onChange={(e) => setSelectedEmployee({ ...selectedEmployee, full_name: e.target.value })}
                />
                <label htmlFor="designation">Designation:</label>
                <input
                  id="designation"
                  name="designation"
                  type="text"
                  value={selectedEmployee.designation}
                  onChange={(e) => setSelectedEmployee({ ...selectedEmployee, designation: e.target.value })}
                />
                <label htmlFor="email_id">Email:</label>
                <input
                  id="email_id"
                  name="email_id"
                  type="email"
                  value={selectedEmployee.email_id}
                  onChange={(e) => setSelectedEmployee({ ...selectedEmployee, email_id: e.target.value })}
                />
                <label htmlFor="mobile_number">Mobile:</label>
                <input
                  id="mobile_number"
                  name="mobile_number"
                  type="text"
                  value={selectedEmployee.mobile_number}
                  onChange={(e) => setSelectedEmployee({ ...selectedEmployee, mobile_number: e.target.value })}
                />
                <button type="submit" className="bg-green-50 hover:bg-green-800 m-1 mt-3 p-1 py-0 border-2 rounded-2">Update</button>
                <button type="button" onClick={closeEditModal} className="bg-rose-50 hover:bg-rose-800 m-1 mt-3 p-1 py-0 border-2 rounded-2">Cancel</button>
              </form>
            </div>
          </div>
        </Wrapper>
      )}
    </>
  );
}

export default EmployeePage;

let Wrapper = styled.div`
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  border: solid red;
}
input{
border: solid;
border-radius: 5px;
    padding-left: 5px;
    margin-left: 5px;
}
.modal-content {
  // background: gray;
      // background: url(https://www.textures4photoshop.com/tex/thumbs/frosted-glass-texture-thumb21.jpg);
      background: url(https://st4.depositphotos.com/20524830/26016/i/450/depositphotos_260162184-stock-photo-large-sheet-shiny-silver-tin.jpg);
  padding: 22px;
  border: solid red;
  border-radius: 8px;
  width: min-content;

  
}
`;

